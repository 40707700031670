import React, { useState }  from 'react';
import Box from '@mui/material/Box';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import HomeIcon from '@mui/icons-material/Home';
import PersonIcon from '@mui/icons-material/Person';
import CodeIcon from '@mui/icons-material/Code';
import InterestsIcon from '@mui/icons-material/Interests';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import Tooltip from '@mui/material/Tooltip';
import EmailIcon from '@mui/icons-material/Email';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import LinkIcon from '@mui/icons-material/Link';
import { ReactComponent as GitlabIcon } from 'assets/gitlab-logo.svg';
import SvgIcon from '@mui/material/SvgIcon';
import Paper from '@mui/material/Paper';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import { APP_ROUTES } from 'router/Routes';

export declare type CustomBottomNavigationProps = React.PropsWithChildren<{}>

const CustomBottomNavigation: React.FC<CustomBottomNavigationProps> = ({
    children
}) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();

    // Define a map from path to index
    const pathToIndex: { [key: string]: number } = {
        [APP_ROUTES.HOME]: 0,
        [APP_ROUTES.EXPERIENCE]: 1,
        [APP_ROUTES.PROJECTS]: 2,
        [APP_ROUTES.INTEREST]: 3,
        [APP_ROUTES.CONTACT]: 4,
        [APP_ROUTES.EASTEREGG]: 5,
    };

    const socials = [
        { icon: <SvgIcon sx={{ fontSize: 40}}><GitlabIcon/></SvgIcon>, name: t('translation:speeddial:gitlab'), link: "https://gitlab.com/KevinShu" },
        { icon: <EmailIcon />, name: t('translation:speeddial:email'), link:"mailto: shu.bingqian@icloud.com" },
        { icon: <LinkedInIcon />, name: t('translation:speeddial:linkedin'), link:"https://www.linkedin.com/in/bingqian-shu-806b62226/" },
        { icon: <FacebookIcon />, name: t('translation:speeddial:facebook'), link:"https://www.facebook.com/profile.php?id=100004150157142"},
        { icon: <InstagramIcon />, name: t('translation:speeddial:instagram'), link:"https://www.instagram.com/kevinshubq/"},
        { icon: <ContactPageIcon />, name: t('translation:speeddial:cv'), link:"https://drive.google.com/drive/folders/1QCU5p93NPE-QBafotc8bhdBnw-B0uCL9?usp=sharing"},
      ]

    // Get the current path
    const currentPath = location.pathname;

    // Set the initial value based on the current path
    const initialValue = pathToIndex[currentPath] ?? 0;
    const [value, setValue] = React.useState(initialValue);

    interface CustomSpeedDialIconProps {
        open: boolean;
    }
    
    const CustomSpeedDialIcon: React.FC<CustomSpeedDialIconProps> = ({ open }) => {
        return (
            <LinkIcon 
                style={{ 
                    transition: 'transform 0.3s ease-in-out', 
                    transform: open ? 'rotate(90deg)' : 'rotate(0deg)',
                }} 
            />
        );
    };

    React.useEffect(() => {
        // Update the value when the location changes
        const newValue = pathToIndex[currentPath] ?? 0;
        if (newValue !== value) {
            setValue(newValue);
        }
    }, [currentPath, value, pathToIndex]);

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleLinkClick = (link: string) => {
        window.open(link, '_blank');
        handleClose();
      };

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
        switch (newValue) {
            case 0:
                navigate(APP_ROUTES.HOME);
                break;
            case 1:
                navigate(APP_ROUTES.EXPERIENCE);
                break;
            case 2:
                navigate(APP_ROUTES.PROJECTS);
                break;
            case 3:
                navigate(APP_ROUTES.INTEREST);
                break;
            case 4:
                navigate(APP_ROUTES.CONTACT);
                break;
            case 5:
                navigate(APP_ROUTES.EASTEREGG);
                break;
            default:
                break;
        }
    };

    return (
        <Box>
            {children}
            <Box sx={{ width: '100%', maxWidth: 500, mx: 'auto' }}>
                <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, width: '100%' }} elevation={3}>
                <Box sx={{ flexGrow: 0 }}>
            <Tooltip title={t('translation:speeddial:title')}>
            <SpeedDial
                ariaLabel="Social SpeedDial"
                sx={{ position: 'absolute', bottom: 80, right: 30 }}
                icon={<CustomSpeedDialIcon open={open} />}
                onOpen={handleOpen}
                onClose={handleClose}
                open={open}
            >
                {socials.map((social) => (
                <SpeedDialAction
                    key={social.name}
                    icon={social.icon}
                    tooltipTitle={social.name}
                    onClick={() => handleLinkClick(social.link)}
                />
                ))}
            </SpeedDial>
            </Tooltip>
          </Box>
                    <BottomNavigation
                        showLabels
                        value={value}
                        onChange={handleChange}
                    >
                        <BottomNavigationAction label={t('translation:buttombar:home')} icon={<HomeIcon />} />
                        <BottomNavigationAction label={t('translation:buttombar:about')} icon={<PersonIcon />} />
                        <BottomNavigationAction label={t('translation:buttombar:projects')} icon={<CodeIcon />} />
                        <BottomNavigationAction label={t('translation:buttombar:interests')} icon={<InterestsIcon />} />
                        <BottomNavigationAction label={t('translation:buttombar:contact')} icon={<ContactSupportIcon />} />
                    </BottomNavigation>
                </Paper>
            </Box>
        </Box>
    )
}

export default CustomBottomNavigation;
