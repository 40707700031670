import React from 'react';
import './index.css';
import {BrowserRouter} from "react-router-dom";
import AppRouter from 'router/AppRouter'
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import CustomBottomNavigation from 'components/organism/ButtomNavigation';
import CustomTopNavigation from 'components/organism/TopNavigation';
import useMediaQuery from '@mui/material/useMediaQuery';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import AnimatedBackground from 'components/molecule/AnimatedBackgroud';

function App() {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode: prefersDarkMode ? 'dark' : 'light',
        },
      }),
    [prefersDarkMode],
  );
  return (
    <BrowserRouter>
    <I18nextProvider i18n={i18n}>
    <ThemeProvider theme={theme}>
        <CssBaseline />
        <AnimatedBackground darkMode={prefersDarkMode}/>
        <CustomTopNavigation/>
        <AppRouter/>
        <CustomBottomNavigation/>
    </ThemeProvider>
    </I18nextProvider>
    </BrowserRouter>
  );
}

export default App;
