import React from 'react';
import { Typography } from '@mui/material';
import CustomPage from 'components/organism/CustomPage';
import { useTranslation } from 'react-i18next';
import useMediaQuery from '@mui/material/useMediaQuery';
import SnakeGame from 'components/molecule/SnakeGame';

const EasterEgg: React.FC = () => {
    const { t } = useTranslation();
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

    return (
        <CustomPage>
            <Typography variant='h2'>{t('translation:easteregg:title')}</Typography>
            <Typography variant='h5'>{t('translation:easteregg:subtitle')}</Typography>
            <Typography variant='h6'>{t('translation:easteregg:subsubtitle')}</Typography>
            <Typography>{t('translation:easteregg:subsubsubtitle')}</Typography>
            <br />
            <SnakeGame darkMode={prefersDarkMode}/>
            <br />
            <Typography>© {new Date().getFullYear()} Bingqian Shu</Typography>
        </CustomPage>
    );
};

export default EasterEgg;
