import {Routes, Route} from 'react-router-dom'
import {APP_ROUTES} from 'router/Routes'
import PublicRoute from 'components/atom/PublicRoute'

import Home from 'apps/Home/Home'
import Contact from 'apps/Contact/Contact'
import EasterEgg from 'apps/EasterEgg/EasterEgg'
import Experience from 'apps/Experience/Experience'
import Projects from 'apps/Projects/Projects'
import Interest from 'apps/Interest/Interest'

const AppRouter = () => {
    return (
        <Routes>
            <Route
                path={APP_ROUTES.ROOT}
                element={
                    <PublicRoute>
                        <Home/>
                    </PublicRoute>
                }
            />
            <Route
                path={APP_ROUTES.HOME}
                element={
                    <PublicRoute>
                        <Home/>
                    </PublicRoute>
                }
            />
            <Route
                path={APP_ROUTES.CONTACT}
                element={
                    <PublicRoute>
                        <Contact/>
                    </PublicRoute>
                }
            />
            <Route
                path={APP_ROUTES.EASTEREGG}
                element={
                    <PublicRoute>
                        <EasterEgg/>
                    </PublicRoute>
                }
            />
            <Route
                path={APP_ROUTES.EXPERIENCE}
                element={
                    <PublicRoute>
                        <Experience/>
                    </PublicRoute>
                }
            />
            <Route
                path={APP_ROUTES.PROJECTS}
                element={
                    <PublicRoute>
                        <Projects/>
                    </PublicRoute>
                }
            />
            <Route
                path={APP_ROUTES.INTEREST}
                element={
                    <PublicRoute>
                        <Interest/>
                    </PublicRoute>
                }
            />
        </Routes>
    )
}

export default AppRouter