import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import TranslateIcon from '@mui/icons-material/Translate';
import CheckIcon from '@mui/icons-material/Check';
import avatar from 'assets/sbq_com.jpeg';
import { useNavigate, useLocation } from 'react-router-dom';
import { APP_ROUTES } from 'router/Routes';
import { useTranslation } from 'react-i18next';
import Tooltip from '@mui/material/Tooltip';
import { CSSTransition } from 'react-transition-group';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import './TopNavigation.css';

function CustomTopNavigation() {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
  const navigate = useNavigate();
  const location = useLocation();
  const { i18n, t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const pages = [
    { label: t('translation:topbar:home'), path: APP_ROUTES.HOME },
    { label: t('translation:topbar:about'), path: APP_ROUTES.EXPERIENCE },
    { label: t('translation:topbar:projects'), path: APP_ROUTES.PROJECTS },
    { label: t('translation:topbar:interests'), path: APP_ROUTES.INTEREST },
    { label: t('translation:topbar:contact'), path: APP_ROUTES.CONTACT },
    { label: '???', path: APP_ROUTES.EASTEREGG },
  ];

  const languages = ['en', 'fr', 'cn']; // Adjust to your available languages

  const changeLanguage = (lang: string) => {
    i18n.changeLanguage(lang);
    handleCloseTranslationMenu();
  };

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleOpenTranslationMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseTranslationMenu = () => {
    setAnchorElUser(null);
  };

  const handleNavigation = (path: string) => {
    navigate(path);
    handleCloseNavMenu();
  };

  const currentPath = location.pathname;
  const showAvatar = currentPath !== '/' && currentPath !== '/home';

  return (
    <AppBar position="fixed">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          {!isMobile && (
            <Box sx={{ width: '56px' }}>
              <CSSTransition
                in={showAvatar}
                timeout={300}
                classNames="slide-avatar"
                unmountOnExit
              >
                <Box
                  sx={{
                    mr: 2,
                    display: { xs: 'none', md: 'flex' },
                    fontFamily: 'monospace',
                    fontWeight: 700,
                    letterSpacing: '.3rem',
                    color: 'inherit',
                    textDecoration: 'none',
                  }}
                >
                  <IconButton sx={{ p: 0 }}>
                    <Avatar alt="Bingqian SHU" src={avatar} />
                  </IconButton>
                </Box>
              </CSSTransition>
            </Box>
          )}
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page.label} onClick={() => handleNavigation(page.path)}>
                  <Typography textAlign="center">{page.label}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <CSSTransition
              in={showAvatar}
              timeout={300}
              classNames="slide-avatar"
              unmountOnExit
            >
          <Box
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            
              <IconButton sx={{ p: 0 }}>
                <Avatar alt="Bingqian SHU" src={avatar} />
              </IconButton>
          </Box>
          </CSSTransition>
          <Box
            className={`menu-container ${showAvatar ? '' : 'menu-expanded'}`}
            sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}
          >
            {pages.map((page) => (
              <Button
                key={t(page.label)}
                onClick={() => handleNavigation(page.path)}
                sx={{ my: 2, color: 'white', display: 'block' }}
              >
                {page.label}
              </Button>
            ))}
          </Box>
          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Select Language">
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                color="inherit"
                onClick={handleOpenTranslationMenu}
                sx={{ p: 0 }}
              >
                <TranslateIcon />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseTranslationMenu}
            >
              {languages.map((lang) => (
                <MenuItem key={lang} onClick={() => changeLanguage(lang)}>
                  <Typography textAlign="center">
                    <Box display="flex" alignItems="center">
                      {lang === 'fr' && <Typography>&#127467;&#127479; Français</Typography>}
                      {lang === 'en' && <Typography>&#127468;&#127463; English</Typography>}
                      {lang === 'cn' && <Typography>&#127464;&#127475; 简体中文</Typography>}
                      {i18n.language === lang && <CheckIcon fontSize="small" sx={{ ml: 1 }} />}
                    </Box>
                  </Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default CustomTopNavigation;
