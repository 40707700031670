// ProjectCard.tsx
import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea, CardActions, Button, Chip, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface ProjectCardProps {
  title: string;
  image: string;
  description: string;
  link: string;
  tags: Array<{
    text: string;
    color: string;
    backgroundColor: string;
  }>;
}

const ProjectCard: React.FC<ProjectCardProps> = ({ title, image, description, link, tags }) => {
  const { t } = useTranslation();
  return (
    <Card sx={{ maxWidth: 345, justifySelf: 'center' }} elevation={9}>
      <CardActionArea href={link} target="_blank">
        <CardMedia
          component="img"
          height="140"
          image={image}
          alt={title}
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {title}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {description}
          </Typography>
          <Stack direction="row" spacing={1} mt={2}>
            {tags.map((tag, index) => (
              <Chip
                key={index}
                label={tag.text}
              />
            ))}
          </Stack>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Button size="small" color="primary" onClick={() => window.open(link, '_blank')}>
          {t('translation:projectcard:learnmore')}
        </Button>
      </CardActions>
    </Card>
  );
};

export default ProjectCard;
