import React from 'react'
import {Navigate, useLocation} from 'react-router-dom'

const PublicRoute: React.FC<{
    children: JSX.Element
}> = ({children}) => {
    const authenticated = !!localStorage.getItem('token')
    const location = useLocation()
    const urlParams = new URLSearchParams(location.search)
    const returnUrl = urlParams.get('returnUrl')

    if (authenticated !== null) {
        if (!authenticated) {
            return children
        }
        return <Navigate to={returnUrl || '/'}/>
    }

    return null
}

export default PublicRoute
