import React from 'react';
import Box from '@mui/material/Box';
import { Container } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

interface CustomPageProps {
  children: React.ReactNode;
}

const CustomPage: React.FC<CustomPageProps> = ({ children }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box
      sx={{
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        bgcolor: 'background.default',
        color: 'text.primary',
        pt: isMobile ? '56px' : '64px', // Adjust padding top for AppBar height
        pb: '56px', // Adjust padding bottom for BottomNavigation height
      }}
    >
      <Container
        sx={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          padding: 2,
          position: 'relative',
        }}
      >
        {children}
        {/* <p id='footer'>© {new Date().getFullYear()} Bingqian Shu</p> */}
      </Container>
    </Box>
  );
};

export default CustomPage;
