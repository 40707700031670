import ReactDOM from 'react-dom/client';
import './index.css';
import App from 'App';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    /*
    * In development mode and strict mode, all useEffect() will be executed twice,
    * StrictMode is temporarily disabled until final deployment
    * */
    // <React.StrictMode>
    <App/>
    // </React.StrictMode>
);
