import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

interface ExperienceDialogProps {
  open: boolean;
  handleClose: () => void;
  experience: any;
}

const ExperienceDialog: React.FC<ExperienceDialogProps> = ({ open, handleClose, experience }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const { t } = useTranslation();

  const handleLinkClick = (link: string) => {
    window.open(link, '_blank');
    handleClose();
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">
        {t(experience.company)}
      </DialogTitle>
      <DialogContent>
        <br/>
            <Box>
                <img height="auto" width="200px" src={experience.logo} alt={t(experience.company) + "-logo"} />
            </Box>
        <br/>
        <Typography variant="h6">
            {t(experience.titleBold)}
        </Typography> 
        <DialogContentText>
          {experience.description.map((desc: any, idx: any) => (
            <React.Fragment key={idx}>
                <Typography>
                {t(desc)}
                </Typography>
            </React.Fragment>
          ))}
          <br/>
            <Typography>
                {t(experience.tagTitle)}
            </Typography>
            <Typography>
                {t(experience.tagText)}
            </Typography>
            <br/>
            <Typography fontSize="small">
                {t(experience.details)}
            </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={()=>handleLinkClick(experience.link)}>
          {t('translation:experiencedialog:linkbutton')}
        </Button>
        <Button onClick={handleClose} autoFocus>
          {t('translation:experiencedialog:close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ExperienceDialog;
