import utc from './assets/utc.png'
import valorisoft from './assets/valorisoft.png'
import shu from './assets/shu.png'
import utseus from './assets/utseus.png'
import canalplus from "./assets/canalplus.png"
import ceph from './assets/ceph.png'
import chatapp from './assets/chatapp.jpg'
import eventcamera from './assets/eventcamera.jpeg'
import mmf from './assets/mmf.png'
import rv from './assets/rv.png'
import shimmer from './assets/shimmer.jpeg'


export function getExperienceData(t: any) {
 return [
  {
    company: 'translation:experience:shu:company',
    link: 'https://www.shu.edu.cn/',
    logo: shu,
    titleBold: 'translation:experience:shu:titlebold',
    titleText: 'translation:experience:shu:titletext',
    startDate: new Date(2018, 9, 1),
    endDate: new Date(2021, 7, 1),
    description: [
      'translation:experience:shu:description1',
      'translation:experience:shu:description2'
    ],
    color: 'blue',
    position: 'center',
    details: 'translation:experience:shu:details',
    tags: ['C', 'C++']
  },
  {
    company: 'translation:experience:utseus:company',
    link: 'https://utseus.shu.edu.cn/fr.htm',
    logo: utseus,
    titleBold: 'translation:experience:utseus:titlebold',
    titleText: 'translation:experience:utseus:titletext',
    startDate: new Date(2018, 9, 1),
    endDate: new Date(2021, 7, 1),
    tagTitle: 'translation:experience:utseus:tagtitle',
    tagText: 'translation:experience:utseus:tagtext',
    description: [
      'translation:experience:utseus:description1',
      'translation:experience:utseus:description2',
    ],
    color: 'red',
    position: 'bottom',
    details: 'translation:experience:utseus:details',
    tags: ['Python', 'Matlab', 'Scilab', 'Unix', 'Shell', 'C++']
  },
  {
    company: 'translation:experience:utc1:company',
    link: 'https://www.utc.fr/',
    logo: utc,
    titleBold: 'translation:experience:utc1:titlebold',
    titleText: 'translation:experience:utc1:titletext',
    startDate: new Date(2021, 9, 1),
    endDate: new Date(2024, 8, 31),
    tagTitle: 'translation:experience:utc1:tagtitle',
    tagText: 'translation:experience:utc1:tagtext',
    description: [
      'translation:experience:utc1:description'
    ],
    color: 'yellow',
    position: 'center',
    details: 'translation:experience:utc1:details',
    tags: ['Go', 'Java', 'Spring', 'React', 'Git',  'Ceph', 'Docker', 'AWS', 'Python', 'R', 'SQL']
  },
  {
    company: 'translation:experience:valorisoft:company',
    link: 'https://www.valorisoft.fr/',
    logo: valorisoft,
    titleBold: 'translation:experience:valorisoft:titlebold',
    titleText: 'translation:experience:valorisoft:titletext',
    startDate: new Date(2022, 2, 14),
    endDate: new Date(2022, 7, 31),
    tagTitle: 'translation:experience:valorisoft:tagtitle',
    tagText: 'translation:experience:valorisoft:tagtext',
    description: [
      'translation:experience:valorisoft:description'
    ],
    color: 'green',
    position: 'bottom',
    details: 'translation:experience:valorisoft:details',
    tags: ['React.js', 'EXPO', 'PWA', 'Typescript', 'Python', 'Django', 'Figma']
  },
  {
    company: 'translation:experience:utc2:company',
    link: 'https://www.utc.fr/',
    logo: utc,
    titleBold: 'translation:experience:utc2:titlebold',
    titleText: 'translation:experience:utc2:titletext',
    startDate: new Date(2023, 9, 1),
    endDate: new Date(2024, 8, 31),
    tagTitle: 'translation:experience:utc2:tagtitle',
    tagText: 'translation:experience:utc2:tagtext',
    description: [
      'translation:experience:utc2:description'
    ],
    color: 'orange',
    position: 'bottom',
    details: 'translation:experience:utc2:details',
    tags: ['Python', 'PyTorch', 'TensorFlow', 'Matplotlib', 'Pandas', 'Seaborn']
  },
  {
    company: 'translation:experience:canalplus:company',
    link: 'https://www.canalplus.com/',
    logo: canalplus,
    titleBold: 'translation:experience:canalplus:titlebold',
    titleText: 'translation:experience:canalplus:titletext',
    startDate: new Date(2024, 1, 1),
    endDate: new Date(2024, 7, 26),
    tagTitle: 'translation:experience:canalplus:tagtitle',
    tagText: 'translation:experience:canalplus:tagtext',
    description: [
      'translation:experience:canalplus:description'
    ],
    color: 'gray2',
    position: 'top',
    details: 'translation:experience:canalplus:details',
    tags: ['Elasticsearch', 'AWS', 'Python', 'Spark', 'Kafka', 'Iceberg', 'DuckDB', 'Java', 'Scala', 'Spring', 'Jenkins']
  },
]
}

export function getProjectsData(t: any) {
return [
  {
    id: 0,
    title: 'translation:projects:nn:title',
    image: eventcamera,
    description: 'translation:projects:nn:description',
    link: 'https://dumas.ccsd.cnrs.fr/dumas-04538536',
    tags: [
      {
        text: 'Python',
        color: 'var(--olive)',
        backgroundColor: 'var(--bg-olive)',
      },
    ]
  },
  {
    id: 1,
    title: 'translation:projects:ecg:title',
    image: shimmer,
    description: 'translation:projects:ecg:description',
    link: 'https://drive.google.com/file/d/1sDZM9yJqUAFI6X6p-ogReI_t0BC3zNII/view?usp=sharing',
    tags: [
      {
        text: 'Shimmer',
        color: 'var(--navy)',
        backgroundColor: 'var(--bg-navy)',
      },
      {
        text: 'Unix',
        color: 'var(--pink)',
        backgroundColor: 'var(--bg-pink)',
      },
      {
        text: 'AWS',
        color: 'var(--teal)',
        backgroundColor: 'var(--bg-teal)',
      },
      {
        text: 'Java',
        color: 'var(--cyan)',
        backgroundColor: 'var(--bg-cyan)',
      },
    ]
  },
  {
    id: 2,
    title: 'translation:projects:sma:title',
    image: mmf,
    description: 'translation:projects:sma:description',
    link: 'https://gitlab.com/KevinShu/ia04-modele-economique-media-flux',
    tags: [
      {
        text: 'Go',
        color: 'var(--maroon)',
        backgroundColor: 'var(--bg-maroon)',
      },
      {
        text: 'Unix',
        color: 'var(--pink)',
        backgroundColor: 'var(--bg-pink)',
      },
    ]
  },
  {
    id: 3,
    title: 'translation:projects:ceph:title',
    image: ceph,
    description: 'translation:projects:ceph:description',
    link: 'https://drive.google.com/drive/folders/1eOqCZA8b_4a4EyqP84qH8AhDmG9I9eJN?usp=sharing',
    tags: [
      {
        text: 'Ceph',
        color: 'var(--red)',
        backgroundColor: 'var(--bg-red)',
      },
      {
        text: 'Unix',
        color: 'var(--pink)',
        backgroundColor: 'var(--bg-pink)',
      },
    ]
  },
  {
    id: 4,
    title: 'translation:projects:ac:title',
    image: chatapp,
    description: 'translation:projects:ac:description',
    link: 'https://gitlab.com/KevinShu/sr03-devoir2',
    tags: [
      {
        text: 'React',
        color: 'var(--blue)',
        backgroundColor: 'var(--bg-blue)',
      },
      {
        text: 'Spring',
        color: 'var(--green)',
        backgroundColor: 'var(--bg-green)',
      },
      {
        text: 'Java',
        color: 'var(--cyan)',
        backgroundColor: 'var(--bg-cyan)',
      },
    ]
  },
  {
    id: 5,
    title: 'translation:projects:vr:title',
    image: rv,
    description: 'translation:projects:vr:description',
    link: 'https://store.steampowered.com/app/2261500/Shoot_VR/',
    tags: [
      {
        text: 'Unity',
        color: 'var(--gray)',
        backgroundColor: 'var(--bg-gray)',
      },
      {
        text: 'C#',
        color: 'var(--purple)',
        backgroundColor: 'var(--bg-purple)',
      },
      {
        text: 'Plastic SCM',
        color: 'var(--orange)',
        backgroundColor: 'var(--bg-orange)',
      },
    ]
  },
]
}