import React, { useEffect, useRef } from 'react';
import './snakegame.css';

function SnakeGame({ darkMode }) {
    const canvasRef = useRef(null);

    useEffect(() => {
        const canvas = canvasRef.current;
        if (!canvas) return; // Early return if canvas is not defined

        const context = canvas.getContext('2d');
        let touchStartPos = { x: 0, y: 0 };
        let touchEndPos = { x: 0, y: 0 };

        var Game = {};
        var Keyboard = {};
        var Component = {};

        Keyboard.Keymap = {
            37: 'left',
            38: 'up',
            39: 'right',
            40: 'down'
        };

        Keyboard.ControllerEvents = function() {
            var self = this;
            this.pressKey = null;
            this.keymap = Keyboard.Keymap;

            document.onkeydown = function(event) {
                if ([37, 38, 39, 40].includes(event.which)) {
                    event.preventDefault(); // Prevent default action for arrow keys
                    self.pressKey = event.which;
                }
            };

            this.getKey = function() {
                return this.keymap[this.pressKey];
            };
        };

        Component.Stage = function(canvas, conf) {  
            this.keyEvent = new Keyboard.ControllerEvents();
            this.width = canvas.width;
            this.height = canvas.height;
            this.length = [];
            this.food = {};
            this.score = 0;
            this.direction = 'right';
            this.conf = {
                cw: 10,
                size: 5,
                fps: 1000
            };

            if (typeof conf == 'object') {
                for (var key in conf) {
                    if (conf.hasOwnProperty(key)) {
                        this.conf[key] = conf[key];
                    }
                }
            }
        };

        Component.Snake = function(canvas, conf) {
            this.stage = new Component.Stage(canvas, conf);

            this.initSnake = function() {
                for (var i = 0; i < this.stage.conf.size; i++) {
                    this.stage.length.push({x: i, y: 0});
                }
            };

            this.initSnake();

            this.initFood = function() {
                this.stage.food = {
                    x: Math.round(Math.random() * (this.stage.width - this.stage.conf.cw) / this.stage.conf.cw), 
                    y: Math.round(Math.random() * (this.stage.height - this.stage.conf.cw) / this.stage.conf.cw), 
                };
            };

            this.initFood();

            this.restart = function() {
                this.stage.length = [];
                this.stage.food = {};
                this.stage.score = 0;
                this.stage.direction = 'right';
                this.stage.keyEvent.pressKey = null;
                this.initSnake();
                this.initFood();
            };
        };

        Game.Draw = function(context, snake) {
            this.drawStage = function() {
                var keyPress = snake.stage.keyEvent.getKey(); 
                if (typeof(keyPress) != 'undefined') {
                    snake.stage.direction = keyPress;
                }

                context.fillStyle = darkMode ? 'black' : 'white';
                context.fillRect(0, 0, snake.stage.width, snake.stage.height);

                var nx = snake.stage.length[0].x;
                var ny = snake.stage.length[0].y;

                switch (snake.stage.direction) {
                    case 'right':
                        nx++;
                        break;
                    case 'left':
                        nx--;
                        break;
                    case 'up':
                        ny--;
                        break;
                    case 'down':
                        ny++;
                        break;
                }

                if (nx == -1) {
                    nx = (snake.stage.width / snake.stage.conf.cw) - 1;
                } else if (nx == (snake.stage.width / snake.stage.conf.cw)) {
                    nx = 0;
                }

                if (ny == -1) {
                    ny = (snake.stage.height / snake.stage.conf.cw) - 1;
                } else if (ny == (snake.stage.height / snake.stage.conf.cw)) {
                    ny = 0;
                }

                if (nx == snake.stage.food.x && ny == snake.stage.food.y) {
                    var tail = {x: nx, y: ny};
                    snake.stage.score++;
                    snake.initFood();
                } else {
                    var tail = snake.stage.length.pop();
                    tail.x = nx;
                    tail.y = ny;	
                }

                snake.stage.length.unshift(tail);

                const cellCounts = {};
                for (var i = 0; i < snake.stage.length.length; i++) {
                    var cell = snake.stage.length[i];
                    const cellKey = `${cell.x},${cell.y}`;
                    cellCounts[cellKey] = (cellCounts[cellKey] || 0) + 1;
                }

                // Draw the snake body, excluding the head
                for (var i = 1; i < snake.stage.length.length; i++) {
                    var cell = snake.stage.length[i];
                    const cellKey = `${cell.x},${cell.y}`;
                    const isDoubled = cellCounts[cellKey] > 1;
                    this.drawCell(cell.x, cell.y, false, isDoubled);
                }

                // Draw the snake head
                var head = snake.stage.length[0];
                this.drawCell(head.x, head.y, true, false);

                this.drawCell(snake.stage.food.x, snake.stage.food.y, false, false);
                context.fillStyle = darkMode ? 'white' : 'black';
                context.fillText('Score: ' + snake.stage.score, 5, (snake.stage.height - 5));
            };

            this.drawCell = function(x, y, isHead, isDoubled) {
                if (darkMode) {
                    if (isHead) {
                        context.fillStyle = 'rgb(255, 255, 255)';
                    } else if (isDoubled) {
                        context.fillStyle = 'rgb(170, 170, 170)';
                    } else {
                        context.fillStyle = 'rgb(89, 89, 89)';
                    }
                } else {
                    if (isHead) {
                        context.fillStyle = 'rgb(0, 0, 0)';
                    } else if (isDoubled) {
                        context.fillStyle = 'rgb(89, 89, 89)';
                    } else {
                        context.fillStyle = 'rgb(170, 170, 170)';
                    }
                }
                context.beginPath();
                context.arc((x * snake.stage.conf.cw + 6), (y * snake.stage.conf.cw + 6), 4, 0, 2*Math.PI, false);    
                context.fill();
            };
        };

        // Function to handle touch start
        function handleTouchStart(e) {
            touchStartPos = {
                x: e.touches[0].clientX,
                y: e.touches[0].clientY
            };
        }

        // Function to handle touch move
        function handleTouchMove(e) {
            e.preventDefault(); // Prevent scrolling when touching the canvas
            touchEndPos = {
                x: e.touches[0].clientX,
                y: e.touches[0].clientY
            };
        }

        // Function to handle touch end
        function handleTouchEnd() {
            const dx = touchEndPos.x - touchStartPos.x;
            const dy = touchEndPos.y - touchStartPos.y;
            const absDx = Math.abs(dx);
            const absDy = Math.abs(dy);

            if (Math.max(absDx, absDy) > 10) { // Threshold for swipe action
                const direction = absDx > absDy ? (dx > 0 ? 'right' : 'left') : (dy > 0 ? 'down' : 'up');
                snake.stage.keyEvent.pressKey = direction === 'right' ? 39 : direction === 'left' ? 37 : direction === 'up' ? 38 : 40;
            }
        }

        // Add touch event listeners
        canvas.addEventListener('touchstart', handleTouchStart, false);
        canvas.addEventListener('touchmove', handleTouchMove, false);
        canvas.addEventListener('touchend', handleTouchEnd, false);

        const snake = new Component.Snake(canvas, { fps: 100, size: 4 });
        const gameDraw = new Game.Draw(context, snake);

        const gameInterval = setInterval(() => {
            gameDraw.drawStage();
        }, snake.stage.conf.fps);

        return () => {
            clearInterval(gameInterval);
            canvas.removeEventListener('touchstart', handleTouchStart);
            canvas.removeEventListener('touchmove', handleTouchMove);
            canvas.removeEventListener('touchend', handleTouchEnd);
            document.onkeydown = null; // Cleanup the keydown event
        };
    }, [darkMode]);

    return (
        <div className="snake-game-container">
            <canvas ref={canvasRef} id="stage" height="300" width="300"></canvas>
        </div>
    );
}

export default SnakeGame;
