import React from 'react';
import {Avatar, Typography} from "@mui/material";
import CustomPage from "../../components/organism/CustomPage";
import { useTranslation } from 'react-i18next';
import sbq from 'assets/sbq_com.jpeg'

const Home: React.FC = () => {
    const { t } = useTranslation();
    return (
        <CustomPage>
            <Avatar sx={{ height: '200px', width: '200px' }} src={sbq} alt="sbq"/>
            <br/>
        <Typography variant="h2">{t('translation:info:title')}</Typography>
        <Typography variant="h6">{t('translation:info:subtitle')}</Typography>
        <br/>
        <br/>
        <Typography variant="body1">
        {t('translation:about:hello')}
        {t('translation:about:fullname')}
        </Typography>
        <Typography variant="body1">
        {t('translation:about:description1')}
        {t('translation:about:bold1')}
        </Typography>
        <Typography variant="body1">
        {t('translation:about:description2')}
        {t('translation:about:bold2')}
        {t('translation:about:description3')}
        </Typography>
        <Typography variant="body1">
        {t('translation:about:description4')}
        {t('translation:about:bold3')}
        </Typography>
        <Typography variant="body1">
        {t('translation:about:description5')}
        {t('translation:about:bold4')}
        {t('translation:about:description6')}
        {t('translation:about:bold5')}
        </Typography>
        </CustomPage>
    );
};

export default Home;