// Experience.tsx
import React, { useState } from 'react';
import { Typography, Chip, Stack } from '@mui/material';
import CustomPage from '../../components/organism/CustomPage';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { getExperienceData } from 'data';
import { useTranslation } from 'react-i18next';
import ExperienceDialog from 'components/organism/dialogs/ExperienceDialog';

const Experience: React.FC = () => {
  const { t } = useTranslation();
  const experienceData: any[] = getExperienceData(t);

  const [openDialogIndex, setOpenDialogIndex] = useState<number | null>(null);

  const handleClickOpen = (index: number) => {
    setOpenDialogIndex(index);
  };

  const handleClose = () => {
    setOpenDialogIndex(null);
  };

  return (
    <CustomPage>
      <Typography variant="h4" gutterBottom>
        {t('translation:experiencepage:title')}
      </Typography>
      <Timeline position="left">
        {experienceData.map((experience, index) => (
            <Box>
          <TimelineItem key={index}>
            <TimelineOppositeContent
              sx={{ m: 'auto 0' }}
              align="right"
              variant="body2"
              color="text.secondary"
            >
              {experience.startDate.toLocaleDateString()} - {experience.endDate.toLocaleDateString()}
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineConnector />
              <Button onClick={() => handleClickOpen(index)}>
                <Box>
                  <img height="auto" width="100px" src={experience.logo} alt={t(experience.company) + "-logo"} />
                </Box>
              </Button>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent sx={{ py: '12px', px: 2 }}>
              <Typography>{t(experience.titleBold)}</Typography>
            </TimelineContent>
          </TimelineItem>
          <Stack direction="row" flexWrap="wrap" alignItems="center" justifyContent="center" gap={0.5} spacing={0.5} mt={2}>
          {experience.tags.map((tag: String, index: number) => (
          <Chip
            key={index}
            label={tag}
            size="small"
          />
        ))}
        </Stack>
          <br/>
          <br/>
        </Box>
        ))}
      </Timeline>

      {experienceData.map((experience, index) => (
        <ExperienceDialog
          key={index}
          open={openDialogIndex === index}
          handleClose={handleClose}
          experience={experience}
        />
      ))}
    </CustomPage>
  );
};

export default Experience;
