// Projects.tsx
import React, { useState } from 'react';
import { Typography, Grid, Paper, Chip, useMediaQuery, useTheme } from '@mui/material';
import CustomPage from 'components/organism/CustomPage';
import { getProjectsData } from 'data';
import ProjectCard from 'components/organism/ProjectCard';
import { useTranslation } from 'react-i18next';

const Projects: React.FC = () => {
  const { t } = useTranslation();
  const projectsData = getProjectsData(t);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const allTags = Array.from(new Set(projectsData.flatMap(project => project.tags.map(tag => tag.text))));

  const handleTagClick = (tag: string) => {
    setSelectedTags((prevTags) =>
      prevTags.includes(tag) ? prevTags.filter((t) => t !== tag) : [...prevTags, tag]
    );
  };

  const filteredProjects = selectedTags.length
    ? projectsData.filter(project =>
        project.tags.some(tag => selectedTags.includes(tag.text))
      )
    : projectsData;

  return (
    <CustomPage>
      <Typography variant="h4" gutterBottom>
        {t('translation:projectpage:titletext')}
        {t('translation:projectpage:titlebold')}
      </Typography>
      <Typography variant="h6" gutterBottom>
        {t('translation:projectpage:description')}
      </Typography>
      <Paper
        sx={{
          display: 'flex',
          justifyContent: 'center',
          flexWrap: 'wrap',
          listStyle: 'none',
          p: 0.5,
          m: 2,
        }}
        component="ul"
      >
        {allTags.map((tag, index) => (
          <Chip
            key={index}
            label={tag}
            onClick={() => handleTagClick(tag)}
            color={selectedTags.includes(tag) ? 'primary' : 'default'}
            sx={{ margin: 0.5 }}
          />
        ))}
      </Paper>
      <Grid container spacing={4} direction={isMobile ? "column" : "row"} alignItems="center" justifyContent="center">
        {filteredProjects.map((project) => (
          <Grid item xs={12} sm={6} md={4} key={project.id}>
            <ProjectCard
              title={t(project.title)}
              image={project.image}
              description={t(project.description)}
              link={project.link}
              tags={project.tags}
            />
          </Grid>
        ))}
      </Grid>
    </CustomPage>
  );
};

export default Projects;
