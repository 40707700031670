import React from 'react';
import {Typography} from "@mui/material";
import CustomPage from "../../components/organism/CustomPage";
import { useTranslation } from 'react-i18next';

const Contact: React.FC = () => {
    const { t } = useTranslation();
    return (
        <CustomPage>
            <Typography variant="h4" gutterBottom>
            {t('translation:contact:titlebold')}{t('translation:contact:titletext')}
            </Typography>
            <Typography variant="h6" gutterBottom>
            {t('translation:contact:description')}
            </Typography>
        
        <a className="email-link" type="email" href='mailto: shu.bingqian@icloud.com' title='Mail'>
        <b>{t('translation:contact:emaillink')}</b>
        </a>
      <p id='footer'>© {new Date().getFullYear()} Bingqian Shu</p>
        </CustomPage>
    );
};

export default Contact;