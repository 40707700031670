import React from 'react';
import {Paper, Typography, Box, useMediaQuery, useTheme} from "@mui/material";
import CustomPage from "../../components/organism/CustomPage";
import { useTranslation } from 'react-i18next';

const Interest: React.FC = () => {
    const theme = useTheme();
    const { t } = useTranslation();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    return (
        <CustomPage>
            <Typography variant="h4" gutterBottom>
            {t('translation:interest:titletext')}{t('translation:interest:titlebold')}
      </Typography>
      <Paper elevation={9}>
        <Box sx={{margin: "15px"}}>
            <Typography variant='h6'>
            {t('translation:interest:interest1text')}{t('translation:interest:interest1bold')}
            </Typography>
            <iframe title="vlog_norway" src="//player.bilibili.com/player.html?aid=482149089&bvid=BV1hT411U7uS&cid=1015854284&p=1" style={{borderRadius: 20}} width={isMobile?"250px" : "400px"} height={isMobile?"150px" : "250px"} frameBorder="no" allowFullScreen={true}> </iframe>
            <Typography variant='h6'>
            {t('translation:interest:interest1sup')}
            </Typography>
        </Box>
      </Paper>
      <br/>
      <Paper elevation={9}>
        <Box sx={{margin: "15px"}}>
            <Typography variant='h6'>
            {t('translation:interest:interest1text')}{t('translation:interest:interest1bold')}
            </Typography>
            <iframe title="vlog_feroe" src="//player.bilibili.com/player.html?isOutside=true&aid=112814077250665&bvid=BV1Gm8AeYE3P&cid=500001620828606&p=1" style={{borderRadius: 20}} width={isMobile?"250px" : "400px"} height={isMobile?"150px" : "250px"} frameBorder="no" allowFullScreen={true}> </iframe>
            <Typography variant='h6'>
            {t('translation:interest:interest1sup2')}
            </Typography>
        </Box>
      </Paper>
      <br/>
      <Paper elevation={9}>
      <Box sx={{margin: "15px"}}>
            <Typography variant='h6'>
            {t('translation:interest:interest2text')}{t('translation:interest:interest2bold')}
            </Typography>
            <img src="https://pic3.zhimg.com/80/v2-9fa7334b905c809e7826c499cafb8912_720w.webp" style={{borderRadius: 20}} width={isMobile?"250px" : "400px"} height={isMobile?"150px" : "250px"} alt=""/>
            </Box>
      </Paper>
        </CustomPage>
    );
};

export default Interest;